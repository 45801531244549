function isScrolledIntoView(el) {
	var rect = el.getBoundingClientRect();
	var elemTop = rect.top;
	var elemBottom = rect.bottom;
	var isVisible;

	// Only completely visible elements return true:
	// isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
	// Partially visible elements return true:
	isVisible = elemTop < window.innerHeight && elemBottom >= 0;
	return isVisible;
}


document.addEventListener('DOMContentLoaded', function(){

	/*Preloader */

	let loaded = 0;
	const preloader = document.querySelector('.preloader'),
				preloaderPaths = preloader?.querySelectorAll('path'),
				len = preloaderPaths?.length;

	const controller = new ScrollMagic.Controller();

	function pathPrepare(el, color, fill = "#FFFFFF") {
		var lineLength = el.getTotalLength();
		el.style.cssText = `
			stroke-dasharray: ${lineLength}px;
			stroke-dashoffset: ${lineLength}px;
			fill: ${fill};
			stroke: ${color};
		`;
		return lineLength;
	}

	function setDelays(paths, delay = 0.1) {

		paths.forEach( (path, i) => {
				path.style.transitionDelay = 	(i * delay) + 's';
		});
	}

	if (preloader) {
		let timerId = setInterval(function() {
			loaded++;

			preloaderPaths.forEach( (path, i) => {
				if (!path.classList.contains('active')) {

					if (i <= (len / 100 * loaded)) {
						path.classList.add('active');
					}
				}
			})

			if (loaded >= 100) {
				clearInterval(timerId);

				setTimeout(function() {
					preloader?.remove();
					initAnimationAfterPreloader();

					const eventPopup = document.querySelector('.popup--power'),
							delay = eventPopup.dataset.delay;

					if (eventPopup) {
						setTimeout(function() {
							eventPopup.classList.add('popup--active');
						}, delay);
					}
				}, 1000);
			}
		}, 10);
	}

	/* Animation */
	const intro = document.querySelector('.intro');
	intro?.classList.add('loaded');

	// observe the scrolling blocks
	const initAnimationAfterPreloader = () => {

		/* leafs */
		const leafs = document.querySelectorAll('.ap-leaf');
		leafs.forEach(leaf => {
			
			const paths = leaf.querySelectorAll('path');

			setDelays(paths);

			new ScrollMagic.Scene({
					triggerElement: leaf,
					duration: 0, // анимация идет на протяжении Х пикселей, 0 - автоматически

					reverse: false // включаем/отключаем обратную анимацию при прокрутке вниз
				})
				.setClassToggle(leaf, 'active')
				.addTo(controller);
		})


		/*signs*/
		const signs = document.querySelectorAll('.person__sign');
		signs.forEach(sign => {

			const paths = sign.querySelectorAll('path');
			const tween = new TimelineMax({paused:true});

			paths.forEach(path => {
				pathPrepare(path, "#000000", "none");
				tween.add(
					TweenMax.to(path, {
						duration: sign.classList.contains('.person__sign--fast') ? 0.5 : 1.5,
						strokeDashoffset: 0,
						ease: Linear.easeNone,
					})
				)
			});

			sign.tween = tween;
		})

		
		let fadersQuery = 'section, footer, .section__photo';
		if (window.innerWidth < 1024) {
			fadersQuery += ', .creation__img-wrp, .square, .prod-prev__text-wrp, .philo__column, .philo__quote--xs';
		}

		const faders = document.querySelectorAll(fadersQuery);

		const appearOptions = {
			threshold: 0.25
		};

		const appearOnScroll = new IntersectionObserver(function (
			entries,
			appearOnScroll
		) {
			entries.forEach((entry) => {

				if (!entry.isIntersecting) {
					return;
				} else {
					entry.target.classList.add('loaded');
					appearOnScroll.unobserve(entry.target);
					
					const sign = entry.target.querySelector('.person__sign');
					if (sign) {
						setTimeout(() => {
							sign.tween?.play();
						}, 500)
					}
				}
			});
		},
		appearOptions);

		faders.forEach((fader) => {
			appearOnScroll.observe(fader);

			// set loaded to sections that have been loaded
			if (fader.getBoundingClientRect().top < 100) {
				fader.classList.add('loaded');
			}

			// this elemetn is too big on mobile
			if (fader.classList.contains('where-list') && window.innerWidth < 1024) {
				fader.classList.add('loaded');
			}
		});
	}

	/* Header */
	const header = document.querySelector('.h'),
			burger = document.querySelector('.h__burger');

	if (burger) {
		burger.addEventListener('click', function() {
			if ( header.classList.contains('h--opened') ) {
				header.classList.remove('h--opened');
				document.body.style.overflow = '';
			} else {
				header.classList.add('h--opened');
				document.body.style.overflow = 'hidden';
			}
		});

		function stickFunc() {

			sticky = header.offsetTop;
	
			let st = window.pageYOffset;
	
			if (st > lastScrollTop){
	
				// Scroll Down
				sticky += header.clientHeight;
	
				header.classList.add('h--nav-down')
				header.classList.remove('h--nav-up');
			} else {
	
				// Scroll Up
				header.classList.add('h--nav-up')
				header.classList.remove('h--nav-down');
			}
			lastScrollTop = st;
	
			if (window.pageYOffset >= 0) {				
				header.classList.add('h--sticky');
			} else {
				header.classList.remove('h--sticky');
			}
		}

		/*
		* sticky mobile header while scrolling
		*/
		let lastScrollTop = 0,
			sticky = 0;
		window.onscroll = function() {
			requestAnimationFrame(stickFunc)
		};
	}


	/* photo slider */
	const photoSliders = document.querySelectorAll('.section__photo-slider');
	photoSliders.forEach(item => {
		let slider;

		const initSlider = () => {

			if (innerWidth < 768) {
				new Swiper(item, {
					spaceBetween: 10,
					slidesPerView: 'auto',
					pagination: {
						el: item.querySelector('.section__slider-paginaton'),
						bulletClass: 'slider-pagination__bullet',
						bulletActiveClass: 'slider-pagination__bullet--active',
						clickable: true
					}
				})
			} else {
				slider?.destroy()
			}
		}

		initSlider();
		document.addEventListener('resize', () => initSlider)
		
	})


	// popups
	const closePopup = popup => {

		popup.classList.remove('popup--active');

		document.body.style.overflow = '';
	}

	document.addEventListener('click', function(e){
		const tg = e.target;

		if (tg.classList.contains('popup')) {
			closePopup(tg);
			return;
		}

		if (tg.closest('.popup__close-btn')) {
			const popup = tg.closest('.popup');			
			closePopup(popup);
		}
	});
	
	document.addEventListener('keyup', function(e){
		if (e.keyCode == 27) {
			const popup = document.querySelector('.popup--active');
			if (popup) {
				closePopup(popup);
			}
		}		
	});

	// open popups
	document.addEventListener('click', function(e){
		const target = e.target.closest('[data-popup-target]');
		
		if (target) {

			const popup = document.getElementById(target.dataset.popupTarget);
			
			popup.classList.add('popup--active');
			document.body.style.overflow = 'hidden';
		
		}
	});

});


// shops
document.addEventListener('DOMContentLoaded', function(){

	const select = document.querySelector('.where__select select');
	if (!select) {
		return;
	}

	const listEl = document.querySelector('.where-list');
	const mapContainer = document.querySelector('.where__map');
	
	let map,
			script = document.createElement('script'),
			prior = document.getElementsByTagName('script')[0];

	let list = [];
	const cityPoints = {
		"moscow": [55.755864, 37.617698],
		"spb": [59.938955, 30.315644],
		"gelendjik": [44.561012, 38.077115],
		"anapa": [44.894818, 37.316367],
		"sochi": [43.585472, 39.723098],
		"novoros": [44.723771, 37.768813],
		"golub": [45.320715, 37.276509],
		"krasnodar": [45.035470, 38.975313],
		"rostov": [47.222078, 39.720358],
		"Taganrog": [47.208735, 38.936694],
		"Eysk": [46.711944, 38.272660],
		"Sevastopol": [44.556972, 33.526402],
		"Tula": [54.193122, 37.617348],
		"kaluga": [54.513678, 36.261341],
		"lipetzk": [52.608826, 39.599229],
		"nigniy-novgorod": [56.326797, 44.006516],
		"tarusa": [54.727671, 37.183965],
		"vladimir": [56.129057, 40.406635]
	}

	const drawShops = () => {
		const value = select.value;
		const name = select.options[select.selectedIndex].textContent;
		let shops = '';
		
		list[value].forEach(shop => {
			shops += `
				<li class="where-list__item">
					<p class="where-list__name">${shop.name}</p>
					<p class="where-list__type">${shop.type}</p>
					<p class="where-list__address">${shop.address}</p>
				</li>
			`;
		})

		listEl.innerHTML = `
			<p class="where-list__header">
				<span>${name}</span>
			</p>
			<ul class="where-list__list">
				${shops}
			</ul>
		`;

		map.setCenter(cityPoints[value]);
	}

	const getShops = () => {

		fetch('/data/shops.json',{
			method: 'get',
		})
			.then(resp => {
				if (!resp.ok) {
					throw new Error(resp.status);
				} else {
					return resp.json();
				}
			})
			.then(data => {
				list = data;
				initMap();
			})
			.catch(err => {
				console.error('An error occured: ' + err);
				cartBtn.disabled = false;
			});
	}

	getShops();

	select.addEventListener('change', () => {
		drawShops();
	});


	const initMap = () => {

		script.async = 1;

		script.onload = script.onreadystatechange = function( _, isAbort ) {
			if(isAbort || !script.readyState || /loaded|complete/.test(script.readyState) ) {
				script.onload = script.onreadystatechange = null;
				script = undefined;

				if(!isAbort){
					ymaps.ready(function () {

						map && map.destroy();
						map = new ymaps.Map(mapContainer, {
								center: [55.755864, 37.617698],
								zoom: 10,
								controls: ['zoomControl']
						});
		
						map.behaviors.disable('scrollZoom');
						
						Object.entries(list).forEach( ([key, value]) => {
							value.forEach(item => {
								
								if (!item.point) {
									return;
								}

								var placemark = new ymaps.Placemark(item.point, {
									balloonContent: `
										<div class="mappop">
											<p class="mappop__header">${item.name}</p>
											<p class="mappop__type">${item.type}</p>
											<p class="mappop__address">${item.address}</p>
										</div>
									`
								}, {
									iconLayout: 'default#image',
									iconImageHref: '/img/pin.svg',
									iconImageSize: [14, 32],
									iconImageOffset: [-7, -16]
								})
						
								map.geoObjects.add(placemark);
							});
							})
						});
				}
			}
		}

		script.src = 'https://api-maps.yandex.ru/2.1/?apikey=7eb0c5d1-ae4b-40f6-8bdc-d1372aa5ed9f&lang=ru_RU';
		prior.parentNode.insertBefore(script, prior);
	}
});
